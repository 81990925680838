.login-container{
	width: 100%;
    max-width: 30em;
    margin: auto;
    text-align: center;
    padding: 10em 1em;
}
.login-container .input-login{
    position: relative;
}
.login-container input{
	margin: 1em 0;
    padding-left: 2.5em;
    padding-right: 2.5em;
}
.login-container img{
    height: 164px;
    margin-bottom: 2em;
}
.login-container .input-login{
    position: relative;
}
.login-container .input-login .anticon{
    position: absolute;
    top: 1.5em;
    z-index: 1;
    left: 1em;
}
.login-container .login-btn{
    background-color: #40A9FF;
    width: 100%;
    margin-top: 1em;
}