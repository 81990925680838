/*********** Modal ***********/
.modal-box .ant-modal-content{
    border-radius: 1em;
    max-width: 450px;
    margin: auto;
    background: #F4F4F4;
}
.modal-box .ant-modal-header{
    border: none;
    border-top-left-radius: 1em !important;    
    border-top-right-radius: 1em !important;
    background: #F4F4F4;
}
.modal-box .box-header{
    text-align: center;
    padding-top: 1em;
}
.modal-box p{
    text-align: center;
}
.modal-box .ant-modal-body{
    padding-top: 10px;
}
.modal-box .ant-modal-footer{
    border: none;
    padding-bottom: 2.5em;
    text-align: center;
}
.modal-box .ant-modal-footer .footer-btn{
    background: #40A9FF;
    border-radius: 2px;
    border: none;
    font-size: 14px;
    height: 32px;
    width: 100%;
}
.field{
    width: 100%;
    display: inline-flex;
    line-height: 2.3;
    margin-bottom: 1em;
    padding-top: 5px;
}
.field .edit-name{
    max-width: 320px;
    display: inline-flex;
    margin-left: 7px;
}
.field .edit-coord {
    max-width: 139px;
    display: inline-flex;
    margin-left: 7px;
}
.field .ant-select{
    width: 30%;
    padding-left: 10px;
}
.pin-modal textarea{
    margin-bottom: 1em;
    padding: 15px;
}
.pin-modal .desc-t{
    text-align: left;
}