body, html{
    width: 100%;
    height: 100%;
    background-color: #F9FBFE;
}
*, ::after, ::before {
    box-sizing: border-box;
}
body {
    margin : 0;
    font-size: 14px;
    overflow: auto !important;
    font-family: "Avenir" !important;
}
#root{
    background-color: #F9FBFE;
    color: #262626;
    min-height: 100vh;
    height: 100%;
}
.center, .center-align {
    text-align: center;
}
.container{
    padding: 20px;
    min-height: 100%;
}
/******* Intput errors *******/
.input-error{
    box-shadow: 0px 0px 5px 0px #FD6A88 !important;
}
.text-error{
    color: #FD6A88;
    font-size: 11px;
    font-weight: bold;
}