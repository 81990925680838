.map-container{
    padding: 2em 1em;
}
.mapImg{
    width: 100%;
}
.coordinates h4 {
    font-size: 19px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 0px;
}
.coordinates .subtitle{
    text-align: center;
    font-size: 15px;
}
.coordinates ul{
    padding-top: 10px;
    margin-left: 3em;
    padding-left: 0;
    border-bottom: 1px solid #D1D1D1;
    max-height: 62vh;
    overflow: auto;

}
.coordinates ul li{
    list-style-type: none;
    padding: 1em 0;
    position: relative;
    border-bottom: 1px solid #D1D1D1;
}
.coordinates ul li b{
    display: block;
}
.coordinates ul li button.edit{
    padding: 7px;
    background: #fff;
    border-radius: 2px;
    border: 1px dashed #40A9FF;
    position: absolute;
    right: 50px;
    bottom: 15px;
    cursor: pointer;
}
button.pin{
    background: #40A9FF;
    border-radius: 2px;
    padding: 8.5px;
    float: right;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border: none;
}
button.pin:hover, button.pin:focus, button.pin:active {
    text-decoration: none;
    background: #40A9FF;
    opacity: 0.8;
}
.pin-site{
    color: #ffffff;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
    text-shadow: 2px 1px 1px black;
    font-size: 12px;
}
.ant-modal-footer .loadingScreen{
    padding: 0 !important;
}
.mapErr{
    position: relative;
    top: 31px;
    text-align: center;
}
.coordinates ul li button.delete {
    background: #fff;
    border-radius: 2px;
    border: 1px dashed #40A9FF;
    position: absolute;
    bottom: 15px;
    cursor: pointer;
    width: 31px;
    height: 32px;
    padding: 5px;
    margin: 0;
    right: 0;
    color: #1990ff;
}