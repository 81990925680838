.login-container{
	width: 100%;
    max-width: 30em;
    margin: auto;
    text-align: center;
    padding: 10em 1em;
}
.login-container input{
	margin: 1em 0;
}
.login-container .title{
	font-size: 2em;
    font-weight: 600;
}
.selected-bar, .selected-bar:hover, .ant-menu-dark .ant-menu-item:hover{
    background-color: #1890ff;
    color: #fff !important;
}
.unselected-bar{
    background-color: transparent !important;
}
.unselected-bar svg, .unselected-bar .ant-menu-title-content{
    color: #a5adb5 !important;
}