.tableImg {
    height: 30px;
    width: 50px;
    border-radius: 2px;
}
.center{
    text-align: center;
}
h4.section-title{
    padding-bottom: 1em;
    font-weight: 500;
    padding-left: 1em;
}
.card-edit{
    min-height: 315px;
    padding: 20px 30px;
    max-width: 700px;
    background-color: #F4F4F4;
    border-radius: 20px;
    font-size: 15px;
    line-height: 2.7;
    margin-left: 5px;
    margin-right: 5px;
}
.label{
    width: 100%;
    display: inline-flex;
}
.card-edit .edit-name{
    display: inline-flex;
    width: 85%;
    height: 38px;
    margin-left: 1em;
}
.card-edit .dragger-edit, .newActivityModal .dragger-edit{
    margin-top: 1.5em;
    margin-bottom: 1em;
}
.ant-switch{
    margin-left: 10px;
}
.ant-upload.ant-upload-drag p.ant-upload-text{
    font-size: 12px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon{
    margin-bottom: 10px;
}
.card-edit .switch-edit{
    margin-left: 2em;
}
.card-edit .save-edit{
    color:  #fff;
    background-color: #40A9FF;
    width: 100%;
    margin-top: 6px;
}
.img-preview{
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 90%;
    max-height: 90%;
    height: auto;
}
.title-preview{
    z-index: 0;
    position: absolute;
    color: #fff;
    background: rgba(196, 196, 196, 0.5);
    border-radius: 0px 5px 5px 0px;
    padding: 0 10px;
    text-align: center;
    font-weight: 700;
    font-size: 19px;
    text-transform: capitalize;
    bottom: 2.8em;
    height: 30px;
    left: 5%;
    line-height: 1.7;
}
.img-subsect{
    width: 100%;
    height: auto;
    border-radius: 10px;
    max-width: 500px;
}
.description{
    padding: 15px;
    margin-bottom: 10px;
}
.card-edit h5.desc-title{
    font-size: 15px;
    font-weight: 100;
    padding-top: 0.5em;
}
.card-edit h5.preview-title{
    font-size: 15px;
    text-align: center;
    font-weight: 700;
    padding-top: 0.8em;
}
.text-desc{
    line-height: 1.5;
    font-size: 14px;
    overflow: auto;
}
.subsection-height{
    min-height: 579px;
}
.icon-name svg{
    margin-right: 8px;
    vertical-align: sub;
    position: absolute;
    left: -10px;
}
.new-activity-btn{
    top: 75px;
    right: 16px;
    color: #fff;
    border: none;
    font-weight: 400;
    position: absolute;
    border-radius: 20px;
}
.new-activity-btn svg{
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 2px;
}
.table-size .ant-table-content{
    overflow-x: auto;
    overflow-y: auto;
    max-height: 75vh;
}
.preview-newAct{
    margin: auto;
    max-width: initial;
    max-height: 200px;
    border-radius: 10px;
}
.avatar-uploader > .ant-upload {
    width: 100%;
    height: 70px;
}
.canvasQR{
    display: none;
}
#components-table-demo-drag-sorting tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

#components-table-demo-drag-sorting tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}