#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
.table-act tr td.ant-table-cell:first-child{
    padding-left: 5px;
}
.logo{
    text-align: center;
}
.logo img{
    width: 82px;
    padding: 0.5em 0;
}
.inline{
    display: inline-block;
}